import { CharCodes } from './char-codes';
import { CameraAngle, VideoFormat, VideoVariantType } from './game';
import { ValidationCheck } from './validation-check';
import ObjectId from 'bson-objectid';

export type EventType =
  | 'time_on_ice'
  | 'face_off'
  | 'shot'
  | 'penalty'
  | 'pass'
  | 'interruption'
  | 'puckPossession'
  | 'videoTag'
  | 'oddMenRush'
  | 'highlight';

export type StrengthState =
  | '5-5'
  | '5-4'
  | '5-3'
  | '4-5'
  | '3-5'
  | '4-4'
  | '4-3'
  | '3-4'
  | '3-3';
export type InterruptionType =
  | 'goal'
  | 'period_start'
  | 'period_end'
  | 'icing'
  | 'puck_blocked_by_goalkeeper'
  | 'offside'
  | 'puck_out'
  | 'penalty'
  | 'unspecified';
export type PenaltyDuration = '2' | '2+2' | '5' | '10' | '20';
export type PenaltyType = 'start' | 'expiration';
export type OddMenRushDetail =
  | '4-3'
  | '4-2'
  | '4-1'
  | '4-0'
  | '3-2'
  | '3-1'
  | '3-0'
  | '2-1'
  | '2-0'
  | '1-0';

export const periods = ['1', '2', '3', '4', '5', '6', '7', 'so'] as const;
export type GamePeriod = (typeof periods)[number];
export type ShotOutcome = 'blocked' | 'miss' | 'on_goal' | 'iron' | 'goal';
export type ShotType =
  | 'wrist_shot'
  | 'slap_shot'
  | 'backhand_shot'
  | 'deflection';
export type ShotScenario =
  | 'regular'
  | 'one_timer'
  | 'rebound'
  | 'quick_release';
export type PassType = 'pass' | 'chip' | 'rim' | 'shot_pass';
export type PassOutcome = 'complete' | 'incomplete';
export type VideoTagType =
  | 'low_break_out_under_pressure'
  | 'board_sector_break_out_under_pressure'
  | 'd_offensive_blue_line_puck_management'
  | 'controlled_offensive_zone_entry';

export type Opponent = 'home' | 'away';

export type HighlightType =
  | 'goal'
  | 'shot'
  | 'save'
  | 'hit'
  | 'blooper'
  | 'highlight_play'
  | 'close_up'
  | 'titles'
  | 'bench'
  | 'stands'
  | 'penalty_box'
  | 'compilation'
  | 'penalty_shot';

export type HighlightPlayback = 'normal' | 'slow_motion';

export type HighlightRating = 1 | 2 | 3 | 4 | 5;

export type ExportStatus =
  | { status: 'not_started' } // default
  | { status: 'pending'; timeoutAt: Date } // Means that we started the upload process, but it hasn't been uploaded to Vimeo yet
  | { status: 'transcoding' } // Uploaded to Vimeo, but transcoded, and has vimeo status 'in_progress', but lambda timed out
  | { status: 'complete'; notifiedOpten: boolean }
  | { status: 'failed'; errorMessage: string };

export interface GoalClip {
  startVideoTime?: number;
  endVideoTime?: number;
  cameraAngle?: CameraAngle;
  format?: VideoFormat;
  variant?: VideoVariantType;
  videoClipUrl?: string;
  vimeoVideoId?: string;
  exportStatus?: ExportStatus;
}

export const periodKeyCodeMap = {
  [CharCodes.digit_one]: '1',
  [CharCodes.digit_two]: '2',
  [CharCodes.digit_three]: '3',
  [CharCodes.digit_four]: '4',
  [CharCodes.digit_five]: '5',
  [CharCodes.digit_six]: '6',
  [CharCodes.digit_seven]: '7',
  [CharCodes.digit_eight]: 'so'
} as const;

export type TeamFaceOffOutcome = 'win' | 'lost';
export interface PositionCoordinate {
  x: number;
  y: number;
}

export interface NetImpactPositionCoordinate {
  netImpactX: number;
  netImpactY: number;
}

export interface AuditChange {
  action: 'create' | 'update' | 'delete';
  changes: Partial<GameEvent>;
}

export interface GameEvent {
  // essential attributes
  _id?: string;
  gameId: string;
  eventType: EventType;

  // common attributes
  xPosition?: number; // horizontal position in meters (0-30m) from left to right with own goal at the bottom
  yPosition?: number; // vertical position in meters (0-60m) from top to bottom with own goal at the bottom
  strengthState: StrengthState;
  period: GamePeriod;
  videoTime: number;
  gameTime: number;
  team: string;
  teamId: string;
  playerNumber?: string;
  playerId?: string;
  isHomeTeamEmptyNet: boolean;
  isAwayTeamEmptyNet: boolean;
  trackId?: number; // reference to track if generated by object tracking

  // time_on_ice attributes
  timeOnIceType?: 'on' | 'off';
  onEventId?: string; // for an off event, represents the on-movement
  score?: number; // computer vision confidence

  // faceoff attributes
  teamFaceOffOutcome?: TeamFaceOffOutcome;
  faceoff_opponent?: string;
  faceoff_opponentId?: string;
  faceoffOutcomeXPosition?: number; // horizontal position in meters (0-30m) from left to right with own goal at the bottom
  faceoffOutcomeYPosition?: number; // vertical position in meters (0-60m) from top to bottom with own goal at the bottom
  faceoffId?: ObjectId; // linkes to redundant face-off for other team

  // shot attributes
  shotOutcome?: ShotOutcome;
  shotScenario?: ShotScenario;
  shotType?: ShotType;
  deflector?: string; // the player who deflected the shot
  deflectorId?: string;
  is_blocked?: boolean; // TODO: remove unused field definition
  assist1?: string; // the player who gave the 1st assist
  assist1Id?: string;
  assist2?: string; // the player who gave the 2nd assist
  assist2Id?: string;
  blocker?: string; // the player who blocked the shot
  blockerId?: string;
  blockerXPosition?: number; // horizontal position in meters (0-30m) from left to right with own goal at the bottom
  blockerYPosition?: number; // vertical position in meters (0-60m) from top to bottom with own goal at the bottom
  has_net_traffic?: boolean;
  net_traffic_causer?: string;
  net_traffic_causerId?: string;
  has_screen?: boolean;
  screener?: string;
  screenerId?: string;
  netImpactX?: number; // x-coordinate of impact on the net
  netImpactY?: number; // y-coordinate of impact on the net
  goalClip?: GoalClip;
  xgValue?: number; // expected goal probability
  xgModel?: string; // type of the xg-model

  // penalty attributes
  fouled_player?: string;
  fouled_playerId?: string;
  penaltyType?: PenaltyType;
  penaltyDuration?: PenaltyDuration;
  penaltyId?: ObjectId;

  // pass attributes
  pass_type?: PassType;
  pass_receiver?: string;
  pass_receiverId?: string;
  is_defensive_zone_exit?: boolean;
  pass_outcome?: PassOutcome;
  receiverXPosition?: number; // horizontal position in meters (0-30m) from left to right with own goal at the bottom
  receiverYPosition?: number; // vertical position in meters (0-60m) from top to bottom with own goal at the bottom

  // interruption attributes
  interruption_type?: InterruptionType;

  // video tag attributes
  videoTag?: VideoTagType;

  // oddMenRush attributes
  oddMenRushDetail?: OddMenRushDetail;

  // highlight attributes
  highlightType?: HighlightType;
  highlightPlayback?: HighlightPlayback;
  highlightRating?: HighlightRating;

  // audit information
  insertDate?: Date;
  modificationDate?: Date;
  insertUser?: string;
  modificationUser?: string;
  source?: '49ing' | 'SIHF';

  // sihf Ids
  sihfGameId?: string;
  sihfTeamId?: string;
  sihfPlayerId?: string;

  // backend validations
  validations?: ValidationCheck[];

  // change audit log
  audit?: AuditChange[];

  // reviews
  error?:
    | '49ing'
    | 'livestats'
    | 'reviewed_game'
    | 'master_game'
    | 'both_wrong'
    | 'both_ok'
    | 'not_sure'
    | 'none';
  remarks?: string;

  updated?: boolean;
  deleted?: boolean;
  draft?: boolean;

  // manual confirmation of event by user
  confirmed?: boolean;

  // available on output object
  plus?: any[];
  minus?: any[];
}
